import React from 'react'



const Heading = ({text})=>{
    return(
        <div className="Heading">
               <p className="heading-p">{text}</p>

          
        </div>
    )
}

export default Heading