import React from 'react';



const CardFooter = ({cardf_title})=>{
    return(

            <div class="CardFooter">
                    <p>{cardf_title}</p>
                    
            </div>
    )
}

export default CardFooter