import React from 'react'

const Info = ({text})=>{
    return(
        <div className="Info">

          <span>{text}</span>
        </div>
    )
}

export default Info